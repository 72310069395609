@media screen and (max-width: 580px) {
    .logo_without_border_design {
        display: block;
    }
    .logo_with_border_design {
        display: none;
    }
    .text_on_banner_slider {
        font-size: 1rem;
    }
    .section_banner_slider .slick-dots {
        left: 46%;
    }
    .section_banner_slider .slick-dots li button::before {
        font-size: 15px !important;
    }
    .section_our_services .slick-arrow {
        display: none !important;
    }
    .heading_design_right, .heading_design_left {
        height: 15px;
    }
    .img_photo_gallery_border {
        left: 20px;
    }
    .img_services {
        height: 150px;
    }
    .navbar .container {
        background: #fff0f0;
    }
}

@media (min-width: 581px) and (max-width: 767px) {
    .logo_without_border_design {
        display: block;
    }
    .logo_with_border_design {
        display: none;
    }
    .navbar .container {
        background: #fff0f0;
    }
    .img_photo_gallery_border {
        left: 20px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .logo_without_border_design {
        display: block;
    }
    .logo_with_border_design {
        display: none;
    }
    .navbar .container {
        background: #fff0f0;
    }
}

@media (min-width: 992px) and (max-width: 1280px) {
    .logo_without_border_design {
        display: none;
    }
    .logo_with_border_design {
        display: block;
    }
}

@media (min-width: 1281px) and (max-width: 1440px) {
    .logo_without_border_design {
        display: none;
    }
    .logo_with_border_design {
        display: block;
    }
}

@media (min-width: 1441px) and (max-width: 1920px) {
    .logo_without_border_design {
        display: none;
    }
    .logo_with_border_design {
        display: block;
    }
}

@media (min-width: 1921px) and (max-width: 2480px) {
    .logo_without_border_design {
        display: none;
    }
    .logo_with_border_design {
        display: block;
    }
}