body {
    background: url(./Media/bg_flowers.png);
    background-size: 100%;
}

@font-face {
    font-family: candaraFont;
    src: url(./Font/Candara.ttf);
}

h1, h2, h3, h4, h5, h6, p, span, button, .btn, a, label, input {
    font-family: candaraFont;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

.logo_with_border_design {
    height: 250px;
    z-index: 9;
}

.top_design {
    width: 100%;
    vertical-align: unset;
}

.navbar {
    /* top: 60px; */
    transition: top .5s;
    height: 60px;
    background: #fff0f0;
    z-index: 9;
}

.img_banner {
    width: 100%;
    height: auto;
    margin-top: 1.5px;
}

.section_banner_slider .slick-arrow {
    display: none !important;
}

.section_banner_slider .slick-list {
    position: relative;
}

.section_banner_slider .slick-dots {
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    bottom: auto !important;
    top: 50%;
    left: 48%;
}

.section_banner_slider .slick-dots li button::before {
    font-size: 20px !important;
    color: #fff !important;
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: white;
}

.section_banner_slider .slick-vertical .slick-slide {
    border: unset !important;
}

.div_banner_slick_parent {
    position: relative;
    text-align: center;
    color: white;
}

.div_text_on_banner_image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #00000036;
    border-radius: 20px;
}

.logo_on_slider {
    width: 50%;
    margin: 0 auto;
}

.text_on_banner_slider {
    color: white;
    font-weight: bold;
    font-size: 4rem;
    text-shadow: 0 0 5px black;
}

.h_tag_heading, .p_tag_services, .p_tag_video_gallery, .p_tag_photo_gallery {
    color: #ee2642;
    text-align: center;
}

.p_tag_services {
    height: 40px;
}

.heading_design_right, .heading_design_left {
    height: 25px;
}

.img_services {
    width: 90%;
    height: 250px;
    margin: 0 auto;
    display: inline-block;
    object-fit: cover;
}

.section_our_services .slick-arrow {
    filter: invert(50%);
}

.section_our_services .slick-arrow::before {
    font-size: 30px;
}

.div_heading {
    padding: 20px 0;
}

.section_video_gallery, .section_vendor_registration {
    background: #fcf3f6;
}

.img_video_gallery {
    display: inline-block;
    width: 100%;
    height: 75%;
    object-fit: cover;
    cursor: pointer;
}

.div_video_gallery_col {
    position: relative;
}

.youtube_icon_center {
    font-size: 50px;
    color: red;
    cursor: pointer;
    position: absolute;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.img_video_gallery:hover~.youtube_icon_center, .youtube_icon_center:hover {
    color: #fcf3f6;
}

.venue_location {
    width: 100%;
    height: 85vh;
}

.section_footer {
    background: #333;
    margin-top: -7px;
}

.footer_logo {
    height: 100px;
    margin: 20px;
}

.modal_logo {
    height: 75px;
    margin: 20px;
}

.div_footer_header {
    height: 100px;
    display: table;
    margin: 20px;
}

.h_tag_footer_header {
    display: table-cell;
    vertical-align: middle;
}

.img_photo_gallery {
    display: inline-block;
    width: 75%;
}

.div_img_photo_gallery {
    position: relative;
    text-align: center;
}

.img_photo_gallery_border {
    position: absolute;
    top: -20px;
    left: 7px;
    width: 100px;
}

.btn_social_icons {
    line-height: 0 !important;
    border: thick #fcf3f6 !important;
    padding: 10px !important;
}

.btn_social_icons:hover {
    background-color: #ee8f9b !important;
}

.p_contact_link:hover {
    cursor: pointer;
    color: #ee8f9b !important;
}

.btn_sticky {
    position: fixed;
    top: 00%;
    right: 15px;
    background-color: #ee2642 !important;
    color: #fff;
    text-align: center;
    z-index: 1040;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    box-shadow: 0 0 5px #ee8f9b;
}

.modal-header .btn-close {
    padding: unset !important;
    margin: unset !important;
}

.logo_without_border_design {
    position: absolute;
    height: 100px;
    border: 1px solid pink;
    border-radius: 20px;
    z-index: 9;
    transition: height 0.5s;
}

.navbar-toggler {
    margin-left: auto;
}

.btn_dhruva {
    background-color: #ee2642 !important;
    color: #fff;
    text-align: center;
}

.img_registration {
    display: inline-block;
    width: 100%;
    height: 90vh;
    object-fit: cover;
}

.div_form_card {
    vertical-align: middle;
    display: table;
    margin: 0 auto;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 10px;
    position: absolute;
}

.form_card {
    width: 300px;
    margin: 0 auto;
    box-shadow: 0 0.5rem 3rem rgba(0, 0, 0);
}

